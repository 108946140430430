
const PING_FRACTION = 1
let session = null;

const randomId = () => {
  let id = ""
  return id
}

const ping = (event) => {
  if (session === null) {
    session = (Math.random() <  PING_FRACTION) ? randomId() : false
  }

  if (session !== false) {
    fetch("/ping.js?" + event, {cache: 'reload'})
  }
}

export default ping
