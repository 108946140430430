import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {Link} from 'react-router-dom'

const MenuTopItem = ({target, caption, onClick=null, title=false, children=[],
                      closeBurgerOnClick=false}) => (
  <li onClick={() => onClick && onClick()} className={classNames({title: title, burgerHide: !title})}>
    { target && 
      ( title ? 
        (<h1><Link to={target}>{caption}</Link></h1>) :
        (<Link to={target}>{caption}</Link>)
      )
    }
    { !target &&
      <a href="#">{caption}</a>}
    { children.length > 0 &&
    <ul>
      {children}
    </ul> }
  </li>
)

MenuTopItem.propTypes = {
  target: PropTypes.string,
  caption: PropTypes.string.isRequired,
  children: PropTypes.array,
  title: PropTypes.bool,
  onClick: PropTypes.func,
  closeBurgerOnClick: PropTypes.bool,
}

export default MenuTopItem
