import React from 'react'
import { Map } from 'google-maps-react'

class GoogleMap extends Map {
  componentDidMount() {
    super.componentDidMount()
    if (this.props.searchBox && !this.searchBox) {
      const input = this.props.searchBox
      let searchBox = new google.maps.places.SearchBox(input);
      this.map.controls[google.maps.ControlPosition.LEFT_TOP].push(input);
      searchBox.addListener('places_changed', () => {
        var places = searchBox.getPlaces();

        if (places.length == 0) {
          return;
        }
        this.map.setCenter(places[0].geometry.location)
        if (this.props.onSearch) {
          this.props.onSearch(places[0])
        }
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    super.componentDidUpdate(prevProps, prevState)
    if (this.props.mapType !== prevProps.mapType) {
      this.map.setMapTypeId(this.props.mapType)
    }

  }
}

export default GoogleMap
