
import React from 'react'
import MenuBurger from './MenuBurger.jsx'
import classNames from 'classnames'

class Menu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {open: false}
  }
  toggle() {
    this.setState({open: !this.state.open})
  }
  close() {
    this.setState({open: false})
  }

  render() {
    return (
        <nav>
          <ul className={classNames({
              open: this.state.open,
              closed: !this.state.open
            })}>
          <MenuBurger open={this.state.open} onClick={() => this.toggle()} /> 
          {this.props.children.map((child, i) => (
            React.cloneElement(child, {onClick: (e) => {
                if (child.props.onClick) { child.props.onClick(e)}
                if (child.props.closeBurgerOnClick) {
                  this.close()
                }
              },
              key: i})
            ))
          }
          </ul>
        </nav>
      )
  }
}

export default Menu;
