import React from 'react'
import Menu from './Menu.jsx'
import MenuTopItem from './MenuTopItem.jsx'
import MenuCollider from './MenuCollider.jsx'
import MainMap from './MainMap.jsx'
import * as geo from '../helpers/geometry.js'
import LatLng from '../helpers/LatLng.js'
import { HashRouter as Router, Route, Switch, Redirect, Link } from "react-router-dom";
import Legal from "./Legal.jsx"
import About from "./About.jsx"
import Consent from "./Consent.jsx"
import ping from '../helpers/ping.js'

import '@fortawesome/fontawesome-free/js/fontawesome'
import '@fortawesome/fontawesome-free/js/solid' // https://fontawesome.com/icons?d=gallery&s=solid&m=free
// import '@fortawesome/fontawesome-free/js/regular' // https://fontawesome.com/icons?d=gallery&s=regular&m=free
import '@fortawesome/fontawesome-free/js/brands' // https://fontawesome.com/icons?d=gallery&s=brands&m=free

const mapTypes = ["roadmap", "terrain", "hybrid", "satellite"]

export default class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = this.initialState()

    Object.assign(this.state, this.hashToState())

    this.toggleCollider = this.toggleCollider.bind(this)
    this.updateGroupCenter = this.updateGroupCenter.bind(this)
    this.toggleFixed = this.toggleFixed.bind(this)
    this.makeShareUrl = this.makeShareUrl.bind(this)

    this.shareRef = React.createRef();
    this.searchRef = React.createRef();
  }

  hashToState() {
    const hash = window.location.hash
    const token = hash.split("/")
    if (token.length != 10) { return }
    if (token[0] != "#") { return }
    if (token[1] != "go") { return }

    const fixed = !!Number.parseInt(token[2])

    const zoom = Number.parseInt(token[3])
    if (isNaN(zoom)) { return }

    const mapTypeId = Number.parseInt(token[4])
    const mapType = mapTypes[mapTypeId]
    if (mapType === undefined) { return }

    const groupCenterLat = Number.parseFloat(token[5])
    if (isNaN(groupCenterLat)) { return }

    const groupCenterLng = Number.parseFloat(token[6])
    if (isNaN(groupCenterLng)) { return }

    const viewCenterLat = Number.parseFloat(token[7])
    if (isNaN(viewCenterLat)) { return }

    const viewCenterLng = Number.parseFloat(token[8])
    if (isNaN(viewCenterLng)) { return }

    const colliders = token[9].split(",")

    let state = {}

    state.initialZoom = zoom
    state.initialMapType = mapType
    state.initialGroupCenter = new LatLng(groupCenterLat, groupCenterLng)
    state.initialViewCenter = new LatLng(viewCenterLat, viewCenterLng)
    state.zoom = zoom
    state.groupCenter = new LatLng(groupCenterLat, groupCenterLng)
    state.viewCenter = new LatLng(viewCenterLat, viewCenterLng)

    state.fixed = fixed
    state.colliders = colliders

    ping("/go")
    
    return state
  }

  initialState() {
    return {
      colliders: [geo.C_LHC], //, geo.C_PS, geo.C_SPS],
      propsId: 0,
      fixed: false,
      initialGroupCenter: geo.CERN_GROUP_CENTER,
      initialViewCenter: null,
      initialZoom: 12,
      initialMapType: 'roadmap',
      groupCenter: geo.CERN_GROUP_CENTER,
      viewCenter: geo.CERN_GROUP_CENTER,
      zoom: 12,
      mapType: "roadmap",
    }
  }

  toggleFixed() {
    ping("/fixed/" + + (!this.state.fixed))
    this.setState({fixed: !this.state.fixed})
  }

  toggleCollider(collider, on) {
    const currently_on = (this.state.colliders.indexOf(collider) > -1)
    let colliders = this.state.colliders
    if (on) {
      if (!currently_on) {
        colliders = this.state.colliders.concat([collider])
      }
    } else {
      if (currently_on) {
        colliders = this.state.colliders.filter(c => c != collider)
      }
    }
    const zoom = geo.get_suggested_zoom(colliders, this.state.groupCenter)
    this.setState({initialZoom: zoom,
                   colliders: colliders})
    ping("/colliders/" + colliders.join(","))
  }

  updateGroupCenter(groupCenter) {
    ping("/labcenter")
    const cm = geo.get_boxcenter_of_shapes(this.state.colliders, groupCenter)
    this.setState({initialGroupCenter: groupCenter,
                   initialViewCenter: cm,
                   propsId: this.state.propsId + 1})
  }

  onCenterChange(groupCenter, viewCenter) {
    this.setState({groupCenter: groupCenter, viewCenter: viewCenter})
  }
  onZoomChange(zoom) {
    this.setState({zoom: zoom})
  }
  onMapTypeChange(mapType) {
    this.setState({mapType: mapType})
  }

  makeShareUrl(encode=false) {
    let url = window.location.origin + window.location.pathname + "#/go/" + 
           (this.state.fixed ? "1" : "0") + "/" +
           this.state.zoom + "/" + 
           mapTypes.indexOf(this.state.mapType) + "/" + 
           this.state.groupCenter.lat.toFixed(5) + "/" + 
           this.state.groupCenter.lng.toFixed(5) + "/" + 
           this.state.viewCenter.lat.toFixed(5) + "/" + 
           this.state.viewCenter.lng.toFixed(5) + "/" + 
           this.state.colliders.join(",")

    if (encode) {
      url = encodeURIComponent(url)
    }
    return url
  }

  render() {
    return (
      <div><Router>
        <Menu>
          <MenuTopItem target="/" caption="HowLargeIsTheLHC" title
            closeBurgerOnClick={true} />
          <MenuTopItem target="/about" caption="About"
            closeBurgerOnClick={true} />
          <MenuTopItem caption="Colliders">
            <MenuCollider
              name="Tevatron" loc="Fermilab"
              checked={this.state.colliders.indexOf(geo.C_TEV) > -1}
              onChange={on => this.toggleCollider(geo.C_TEV, on)}
              onLocate={() => this.updateGroupCenter(geo.FERMILAB_GROUP_CENTER)}
              color="#AA00FF"
              info="https://www.fnal.gov/pub/tevatron/tevatron-accelerator.html" />
            <MenuCollider
              name="Proton Synchrotron" abbr="PS" loc="CERN"
              checked={this.state.colliders.indexOf(geo.C_PS) > -1}
              onChange={on => this.toggleCollider(geo.C_PS, on)}
              onLocate={() => this.updateGroupCenter(geo.CERN_GROUP_CENTER)}
              color="#00CC33"
              info="https://home.cern/science/accelerators/proton-synchrotron" />
            <MenuCollider
              name="Super Proton Synchrotron" abbr="SPS" loc="CERN"
              checked={this.state.colliders.indexOf(geo.C_SPS) > -1}
              onChange={on => this.toggleCollider(geo.C_SPS, on)}
              onLocate={() => this.updateGroupCenter(geo.CERN_GROUP_CENTER)}
              color="#00CC99"
              info="https://home.cern/science/accelerators/super-proton-synchrotron" />
            <MenuCollider
              name="Large Hadron Collider" abbr="LHC" loc="CERN"
              checked={this.state.colliders.indexOf(geo.C_LHC) > -1}
              onChange={on => this.toggleCollider(geo.C_LHC, on)}
              onLocate={() => this.updateGroupCenter(geo.CERN_GROUP_CENTER)}
              color="#0099CC"
              info="https://home.cern/science/accelerators/large-hadron-collider" />
            <MenuCollider
              name="Future Circular Collider" abbr="FCC" future loc="CERN"
              checked={this.state.colliders.indexOf(geo.C_FCC) > -1}
              onChange={on => this.toggleCollider(geo.C_FCC, on)}
              onLocate={() => this.updateGroupCenter(geo.CERN_GROUP_CENTER)}
              color="#0000FF"
              info="https://home.cern/science/accelerators/future-circular-collider" />
            <MenuCollider
              name="Compact Linear Collider" abbr="CLIC" future loc="CERN"
              checked={this.state.colliders.indexOf(geo.C_CLIC) > -1}
              onChange={on => this.toggleCollider(geo.C_CLIC, on)}
              onLocate={() => this.updateGroupCenter(geo.CERN_GROUP_CENTER)}
              color="#338800"
              info="http://www.linearcollider.org/CLIC" />
            <MenuCollider
              name="International Linear Collider" abbr="ILC" future loc="Japan"
              checked={this.state.colliders.indexOf(geo.C_ILC) > -1}
              onChange={on => this.toggleCollider(geo.C_ILC, on)}
              onLocate={() => this.updateGroupCenter(geo.KITAKAMI_GROUP_CENTER)}
              color="#CC0000"
              info="http://www.linearcollider.org/ILC/" />
          </MenuTopItem>
          <MenuTopItem
            closeBurgerOnClick={true}
            caption={this.state.fixed ? "Fixed" : "Floating"}
            onClick={() => this.toggleFixed()} />
          <MenuTopItem target="#" caption="Share">
            <li className="share">Share the current view:</li>
            <li>
              <a href={"https://www.facebook.com/sharer/sharer.php?u=" + this.makeShareUrl(true)}
                 target="_blank" rel="noopener nofollow"
								 className="share-button" title="Share on Facebook" style={{color: "#3b5998"}}>
                <i className="fab fa-facebook-square"></i>
              </a>
              {' '}
              <a href={"https://reddit.com/submit?title=How%20large%20is%20the%20LHC&url=" + this.makeShareUrl(true)}
                 target="_blank" rel="noopener nofollow"
                className="share-button" title="Share on Reddit" style={{color: "#ff4500"}}>
                <i className="fab fa-reddit-square"></i>
              </a>
              {' '}
              <a href={"whatsapp://send?text=" + this.makeShareUrl(true)}
                 rel="noopener nofollow"
                 className="share-button" title="Share on Whatsapp" style={{color: "#5cbe4a"}}>
                <i className="fab fa-whatsapp-square"></i>
              </a>
              {' '}
              <a href={"mailto:?subject=How%20large%20is%20the%20LHC&body=" + this.makeShareUrl(true) }
                 rel="noopener nofollow"
							   className="share-button" title="Share via Email" style={{color: "#2e5978"}}>
                <i className="fas fa-envelope-square"></i>
              </a>
            </li>
						<li>
              <input
                type="text"
                readOnly autoFocus
                value={this.makeShareUrl()}
                ref={this.shareRef} />
              <button onClick={() => {
                ping("/share")
                const node = this.shareRef.current
                let range = document.createRange()

                node.contentEditable = true
                node.readOnly = false
                range.selectNodeContents(node)
                let selection = window.getSelection()
                selection.removeAllRanges()
                selection.addRange(range)

                node.setSelectionRange(0, 999999)

                node.contentEditable = false
                node.readOnly = false

                document.execCommand('copy');
                node.value = "  ...copied..."
                setTimeout(() => {node.value = this.makeShareUrl()}, 400)

              }}> <i className="fas fa-clipboard"></i> </button>
						</li>
          </MenuTopItem>

          <MenuTopItem target="/legal" caption="Legal"
            closeBurgerOnClick={true} />
        </Menu>
        <main>
          <Switch>
            <Route path="/about" component={About} />
            <Route path="/legal" component={Legal} />
            <Route path="/" exact render={(routeProps) => (
              <Consent>
                <MainMap {...routeProps}
                  colliders={this.state.colliders}
                  propsId={this.state.propsId}
                  fixed={this.state.fixed}
                  searchRef={this.searchRef}
                  onCenterChange={(group, view) => this.onCenterChange(group, view)}
                  onZoomChange={(zoom) => this.onZoomChange(zoom)}
                  onMapTypeChange={(mapType) => this.onMapTypeChange(mapType)}
                  initialGroupCenter={this.state.initialGroupCenter}
                  initialViewCenter={this.state.initialViewCenter}
                  initialMapType={this.state.initialMapType}
                  initialZoom={this.state.initialZoom} />
                <input  id="pac-input" className="controlls" type="text" placeholder="Search" ref={this.searchRef} />
              </Consent>
            )} />
            <Route render={() => {
              const newState = Object.assign({propsId: this.state.propsId + 1}, this.hashToState())
              setTimeout(() => this.setState(newState), 1)
              return (<Redirect to="/" />)
            }} />
          </Switch>
        </main>
      </Router></div>
    )
  }
}
