import "core-js/stable";
import "regenerator-runtime/runtime";
import 'whatwg-fetch'

import './common.scss'

import ReactDOM from 'react-dom';
import React from 'react';
import App from './components/App.jsx';

const load = () => {
  ReactDOM.render(<App />,
    document.querySelector('#app-root')
  );
};

if (document.readyState !== 'complete') {
  document.addEventListener('DOMContentLoaded', load);
} else {
  load();
}
