
import LatLng from './LatLng'
import * as latlng from './LatLng'

const R_EARTH = 6371;

export const circle = ({lat, lng}, r) => {
  return arc({lat, lng}, r, 0, 360)
}

export const arc = ({lat, lng}, r, start, stop) => {
  const latScale = 360 / (2 * Math.PI * R_EARTH);
  const lngScale = latScale / Math.cos(2 * Math.PI * lat / 360);

  const N = 60;

  return Array.from(Array(N+1).keys()).map(i => (
      {lat: lat + latScale * r * Math.sin(2 * Math.PI * (start + i / N * (stop - start)) / 360),
       lng: lng + lngScale * r * Math.cos(2 * Math.PI * (start + i / N * (stop - start)) / 360)}
  ))
}

export const linear = (center, angle, length) => {
  const N = 10;

  return Array.from(Array(N+1).keys()).map(i => (
    center.go(angle, length * (i - N / 2) / N).dict()
  ))

}

export const get_ps_shape = (groupCenter) => {
  return circle(groupCenter.retrace(CERN_GROUP_CENTER, PS_CENTER), 0.1)
}
export const get_sps_shape = (groupCenter) => {
  return circle(groupCenter.retrace(CERN_GROUP_CENTER, SPS_CENTER), 1.1)
}
export const get_lhc_shape = (groupCenter) => {
  let length = 26700  // in m
  let lhc_center = groupCenter.retrace(CERN_GROUP_CENTER, LHC_CENTER)
  let straight_section = 528 // in m
  let offset = straight_section / 2 / Math.sin(Math.PI / 8) // in m
  let tilt = -14
  let arc_radius = (length - 8 * straight_section) / Math.PI / 2
  console.log(arc_radius)

  let points = Array()
  for (let i = 0; i < 8; i++) {
    let offset_center = lhc_center.go(-tilt + 90 + -22.5 - 45 * i, offset)
    // points.push(offset_center)
    points.push(...arc(offset_center,
      arc_radius / 1000, tilt + 45 * i, tilt + 45 * (i + 1)))
    // points.push(offset_center)
  }
  // return circle(groupCenter.retrace(CERN_GROUP_CENTER, LHC_CENTER), 4.3)
  return points
}
export const get_tevatron_shape = (groupCenter) => {
  return circle(groupCenter.retrace(FERMILAB_GROUP_CENTER, TEV_CENTER), 1.0)
}
export const get_fcc_shape = (groupCenter) => {
  return circle(groupCenter.retrace(CERN_GROUP_CENTER, FCC_CENTER), 16)
}
export const get_clic_s3_shape = (groupCenter) => {
  const center = groupCenter.retrace(CERN_GROUP_CENTER, CLIC_CENTER)
  return linear(center, 40, 50100)
}
export const get_clic_s2_shape = (groupCenter) => {
  const center = groupCenter.retrace(CERN_GROUP_CENTER, CLIC_CENTER)
  return linear(center, 40, 29000)
}
export const get_clic_s1_shape = (groupCenter) => {
  const center = groupCenter.retrace(CERN_GROUP_CENTER, CLIC_CENTER)
  return linear(center, 40, 11400)
}

export const get_ilc_p1_shape = (groupCenter) => {
  const center = groupCenter.retrace(KITAKAMI_GROUP_CENTER, ILC_CENTER)
  return linear(center, -20, 30550)
}

export const get_ilc_p2_shape = (groupCenter) => {
  const center = groupCenter.retrace(KITAKAMI_GROUP_CENTER, ILC_CENTER)
  return linear(center, -20, 50000)
}

export const CERN_GROUP_CENTER = new LatLng(46.229548, 6.054318)
export const PS_CENTER = new LatLng(46.23220269036065, 6.048698044684482)
export const SPS_CENTER = new LatLng(46.245132690027624, 6.055892357711909)
export const LHC_CENTER = new LatLng(46.272832690360645, 6.065298044684482)
export const FCC_CENTER = new LatLng(46.11413269036065, 6.102898044684482)
export const CLIC_CENTER = new LatLng(46.274132690360645, 6.062898044684482)

export const FERMILAB_GROUP_CENTER = new LatLng(41.831944,-88.257222)
export const TEV_CENTER = new LatLng(41.831637, -88.252193)

export const KITAKAMI_GROUP_CENTER = new LatLng(39.023458317744065, 141.39168161146)
export const ILC_CENTER = KITAKAMI_GROUP_CENTER

export const C_PS = "PS"
export const C_SPS = "SPS"
export const C_TEV = "TEVATRON"
export const C_LHC = "LHC"
export const C_FCC = "FCC"
export const C_ILC = "ILC"
export const C_CLIC = "CLIC"

export const get_boxcenter_of_shapes = (keys, groupCenter) => {
  if (keys.length == 0) {
    return groupCenter
  }
  let shapes = []
  if (keys.indexOf(C_PS) > -1) {
    shapes.push(get_ps_shape(groupCenter))
  }
  if (keys.indexOf(C_SPS) > -1) {
    shapes.push(get_sps_shape(groupCenter))
  }
  if (keys.indexOf(C_LHC) > -1) {
    shapes.push(get_lhc_shape(groupCenter))
  }
  if (keys.indexOf(C_FCC) > -1) {
    shapes.push(get_fcc_shape(groupCenter))
  }
  if (keys.indexOf(C_TEV) > -1) {
    shapes.push(get_tevatron_shape(groupCenter))
  }
  if (keys.indexOf(C_CLIC) > -1) {
    shapes.push(get_clic_s3_shape(groupCenter))
  }
  if (keys.indexOf(C_ILC) > -1) {
    shapes.push(get_ilc_p2_shape(groupCenter))
  }

  const points = [].concat.apply([], shapes)

  const lats = points.map(latlng => latlng.lat)
  const lngs = points.map(latlng => latlng.lng)

  const min = new LatLng(Math.min(...lats), Math.min(...lngs)) 
  const max = new LatLng(Math.max(...lats), Math.max(...lngs)) 

  return latlng.center_of_mass(min, max)
}

export const get_suggested_zoom = (keys, groupCenter) => {
  if (keys.length == 0) {
    return 13
  }
  let shapes = []
  if (keys.indexOf(C_PS) > -1) {
    shapes.push(get_ps_shape(groupCenter))
  }
  if (keys.indexOf(C_SPS) > -1) {
    shapes.push(get_sps_shape(groupCenter))
  }
  if (keys.indexOf(C_LHC) > -1) {
    shapes.push(get_lhc_shape(groupCenter))
  }
  if (keys.indexOf(C_FCC) > -1) {
    shapes.push(get_fcc_shape(groupCenter))
  }
  if (keys.indexOf(C_TEV) > -1) {
    shapes.push(get_tevatron_shape(groupCenter))
  }
  if (keys.indexOf(C_CLIC) > -1) {
    shapes.push(get_clic_s3_shape(groupCenter))
  }
  if (keys.indexOf(C_ILC) > -1) {
    shapes.push(get_ilc_p2_shape(groupCenter))
  }

  const points = [].concat.apply([], shapes)

  const lats = points.map(latlng => latlng.lat)
  const lngs = points.map(latlng => latlng.lng)

  const min = new LatLng(Math.min(...lats), Math.min(...lngs)) 
  const max = new LatLng(Math.max(...lats), Math.max(...lngs)) 

  const distance = min.distance(max)

  return Math.round(25.6 - Math.log2(distance))
}
