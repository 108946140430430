
/**
 * Earths radius
 */
const R_EARTH = 6371000;

class LatLng {
  /**
   * Representation of a point given by coordinates. Angles are in degree.
   * @constructor
   * @param first - Latitude or other LatLng object
   * @param second - Longitude or undefined
   */
  constructor(first, second, third) {
    if (third == undefined) {
      if (second == undefined) {
        this.lat = first.lat
        this.lng = first.lng
      } else {
        this.lat = first
        this.lng = second
      }
    } else {
			const radius_xy = Math.sqrt(first**2 + second**2)

			if (radius_xy == 0) {
				if (third > 0) {
					return new LatLng(90, 0)
				} else {
					return new LatLng(-90, 0)
				}
			}

			this.lat = deg(Math.atan(third / radius_xy))
			this.lng = deg(Math.atan2(second / radius_xy, first / radius_xy))
    }
  }

  /**
   * Returns a new object obtained by moving 'distance' meters into 'angle'
   * direction (North = 0, East = 90, ...).
   * @param angle - Direction of movement
   * @param distance - Distance moved in given direction
   */
  go(angle, distance) {
    const walk = new LatLng(distance / (2 * Math.PI * R_EARTH) * 360, 0)
    return walk.rotate(new LatLng(0, 0), -angle)
               .rotate(new LatLng(90, 0), this.lng)
               .rotate(new LatLng(0, this.lng - 90), this.lat)
  }

  /**
   * Returns a new object rotated by 'angle' around axis.
   * @param axis - Axis of the rotation
   * @param angle - Angle of the rotation
   */
  rotate(axis, angle) {
    const axis_length = Math.sqrt(axis.x()**2+axis.y()**2+axis.z()**2)
    const k_x = axis.x() / axis_length
    const k_y = axis.y() / axis_length
    const k_z = axis.z() / axis_length

    angle = rad(angle)

    const x = (this.x() * Math.cos(angle) 
      + (k_y * this.z() - k_z * this.y()) * Math.sin(angle) 
      + k_x * (k_x * this.x() + k_y * this.y() + k_z * this.z()) 
          * (1 - Math.cos(angle)))
    const y = (this.y() * Math.cos(angle) 
      + (k_z * this.x() - k_x * this.z()) * Math.sin(angle)
      + k_y * (k_x * this.x() + k_y * this.y() + k_z * this.z()) 
          * (1 - Math.cos(angle)))
    const z = (this.z() * Math.cos(angle)
      + (k_x * this.y() - k_y * this.x()) * Math.sin(angle)
      + k_z * (k_x * this.x() + k_y * this.y() + k_z * this.z())
          * (1 - Math.cos(angle)))

    const radius_xy = Math.sqrt(x**2 + y**2)

    if (radius_xy == 0) {
      if (z > 0) {
        return new LatLng(90, 0)
      } else {
        return new LatLng(-90, 0)
      }
    }

    const lat = deg(Math.atan(z / radius_xy))
    const lng = deg(Math.atan2(y / radius_xy, x / radius_xy))

    return new LatLng(lat, lng)
  }

  dict() {
    return {lat: this.lat, lng: this.lng}
  }

  x() {
    return R_EARTH * Math.cos(rad(this.lat)) * Math.cos(rad(this.lng))
  }

  y() {
    return R_EARTH * Math.cos(rad(this.lat)) * Math.sin(rad(this.lng))
  }

  z() {
    return R_EARTH * Math.sin(rad(this.lat))
  }

  /**
   * Dot product with given vector.
   */
  dot(a) {
    return this.x() * a.x() + this.y() * a.y() + this.z() * a.z()
  }

  /**
   * Vector product with given vector.
   */
  vector(a) {
		const x = this.y() * a.z() - this.z() * a.y()
		const y = this.z() * a.x() - this.x() * a.z()
    const z = this.x() * a.y() - this.y() * a.x()

    return new LatLng(x, y, z)
  }

  /**
   * Similar as go(). The distance and angle is determined by the relative
   * position of end towards start.
   *
   *   a.retrace(a, b) returns b.
   */
  retrace(a, b) {
    const distance = a.distance(b)
    const angle = a.angle(b)
    return this.go(angle, distance)
  }

  distance(p) {
    return 2 * Math.asin(Math.sqrt(
      Math.sin(rad(this.lat - p.lat) / 2)**2
      + Math.cos(rad(this.lat)) * Math.cos(rad(p.lat)) * Math.sin(rad(this.lng - p.lng) / 2)**2
    )) * R_EARTH
  }

  angle(p) {
    if (this.lat == 90) {
      return -180
    }
    if (this.lat == -90) {
      return 0
    }

    const N = new LatLng(90, 0)
    return deg(Math.acos(this.vector(p).dot(this.vector(N)) / R_EARTH**2) *
        ((this.lng - p.lng + 360) % 360 > 180 ? 1 : -1)) || 0
  }

}

export const rad = phi => (Math.PI * phi / 180)
export const deg = phi => (180 * phi / Math.PI)

/**
 * Returns the center of mass of all given LatLng object.
 */
export const center_of_mass = (...latlngs) => {
  let x = 0
  let y = 0
  let z = 0

  latlngs.forEach(p => {
    x += p.x()
    y += p.y()
    z += p.z()
  })

  if (x**2 + y**2 + z**2 < 1e-10) {
    return new LatLng(0, 0)
  }

  const radius_xy = Math.sqrt(x**2 + y**2)

  if (radius_xy == 0) {
    if (z > 0) {
      return new LatLng(90, 0)
    } else {
      return new LatLng(-90, 0)
    }
  }

  const lat = deg(Math.atan(z / radius_xy))
  const lng = deg(Math.atan2(y / radius_xy, x / radius_xy))

  return new LatLng(lat, lng)
}

export default LatLng
