
import React from 'react'
import LatLng from '../helpers/LatLng.js'
import {Link} from 'react-router-dom'
import ping from '../helpers/ping.js'

class About extends React.Component {
  constructor(props) {
    super(props)
    this.state = {userLoc: null}
    this.setUserLoc = this.setUserLoc.bind(this)
    try {
      navigator.geolocation.getCurrentPosition(this.setUserLoc)
    } catch(e) {
      console.error(e)
    }
  }

  componentDidMount() {
    ping("/about")
  }
  
  setUserLoc(pos) {
    this.setState({userLoc: new LatLng(pos.coords.latitude, pos.coords.longitude)})
  }

  render() {
    return (
      <div className="nog"><div>
        <h2><a href="https://howlargeisthelhc.com">How Large is the LHC?</a></h2>
        <p>
          The purpose of this website is to illustrate the size of the <a
          href="https://videos.cern.ch/record/1125472"
          target="_blank">Large Hadron
          Collider (LHC)</a> at <a href="https://home.cern/about"
          target="_blank">CERN</a> in Geneva. The LHC is the worlds most
          powerful <a href="https://home.cern/science/accelerators/how-accelerator-works">particle
          accelerator</a>. For me, the most significant scientific
          breakthrough at the LHC is the <a
          href="https://home.cern/news/press-release/cern/cern-experiments-observe-particle-consistent-long-sought-higgs-boson"
          target="_blank">discovery</a> of the <a
          href="https://home.cern/news/news/physics/basics-higgs-boson"
          target="_blank">Higgs
          boson</a> in 2012.
        </p>
        <p className="figure">
          <img src="/lhc_pipe.jpg"
            alt="Photo of the underground tunnel with the LHC"
            title="Photo of the underground tunnel with the LHC" /><br /> 
          Photo of the underground tunnel with the LHC © 2005 CERN 
        </p>
        <p>
          Particle accelerators are huge machines. The LHC consists of a <a
          href="https://home.cern/resources/faqs/facts-and-figures-about-lhc"
          target="_blank">27-kilometer
          long beam-pipe</a>. This sounds large but how large is it? Usually, when
          you see an illustration of the LHC on a map, it's a map of the LHC at its
          actual location. However, to understand how large the LHC is, it is
          more instructive to see the LHC ring on a map of your home town.
        </p>
        <p style={{fontWeight: "bold"}}>With this website, you can move the LHC
        and other (future) colliders on a map to any place on earth.</p>
        <p>Ask yourself:</p>

        <ul>
          <li>
            <Link to="/go/0/12/0/48.81457/2.33466/48.85778/2.33986/LHC">
              Would Paris fit inside the LHC ring?
            </Link>
          </li>
          <li>
            <Link to="/go/0/12/1/62.00330/-6.82500/62.01413/-6.82782/SPS">
              Would the SPS fit on to the Faroe Islands?
            </Link>
          </li>
          { this.state.userLoc && 
            <li><Link to={"/go/0/12/0/" + this.state.userLoc.lat.toFixed(5) + 
                       "/" + this.state.userLoc.lng.toFixed(5) +
                       "/" + this.state.userLoc.lat.toFixed(5) +
                       "/" + this.state.userLoc.lng.toFixed(5) +
                       "/FCC"}>
              Could we build the FCC in your neighborhood?
            </Link></li> }
          { ! this.state.userLoc &&
              <li>Could we build the FCC in your neighborhood?</li>
          }
        </ul>
        <p>Try it yourself. Go the <Link to="/">map view</Link> and move the
        colliders around.</p>

        <p>
          This website will not explain what <a
          href="https://home.cern/resources/video/cern/cern-and-rise-standard-model"
          target="_blank">scientists do at particle
          accelerators</a> or what
          the <a href="https://videos.cern.ch/record/2653671" target="_blank">plans for future
          colliders</a> are.
          The site is meant as a way to visualize the size of particle accelerators
          for non-physicists. The size, shape, and location is not entirely
          accurate. The selection of accelerators and the site of future colliders
          should not convey any valuation.
        </p>

      </div></div>
    )
  }
}

    

export default About
