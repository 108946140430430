import React from 'react'
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom'
import styled from 'styled-components';

const Div = styled.div`
padding: 3px 1em;
`
const Bar = styled.div`
  width: 25px;
  height: 3px;
	padding: 0;
  background-color: black;
  margin: 5px 0;
`
const LeftBar = styled.div`
  width: 22px;
  height: 3px;
	padding: 0;
  background-color: black;
  margin: 5px 0;
  transform: translate(0px, +8px) rotate(-43deg) ;
`
const RightBar = styled.div`
  width: 22px;
  height: 3px;
	padding: 0;
  background-color: black;
  margin: 5px 0;
  transform: translate(0px, -0px) rotate(+43deg) ;
`

const MenuBurger = ({onClick, open}) => (
  <li className="burger" onClick={
    (e) =>  {e.preventDefault(); onClick()}
  }>
		{ !open && <Div><Bar /><Bar /><Bar /></Div>}
		{ open && <Div><LeftBar /><RightBar /></Div>}
  </li>
)

MenuBurger.propTypes = {
  onClick: PropTypes.func,
	open: PropTypes.bool,
}

export default MenuBurger
