import React from 'react'
import PropTypes from 'prop-types'
import { GoogleApiWrapper, Polyline, Polygon } from 'google-maps-react'
import GoogleMap from './GoogleMap.jsx'
import * as geo from '../helpers/geometry.js'
import LatLng from '../helpers/LatLng.js'
import ping from '../helpers/ping.js'

const ZERO = new LatLng(0, 0)


/*
 * props.fixed:
 *
 * If true, the groupCenter is used as absolute coordinates.
 *
 * If false, the groupCenterDiff is used as the diff to the view center.
 */
 
export class MapContainer extends React.Component {
	constructor(props) {
    super(props)
    const cm = geo.get_boxcenter_of_shapes(this.props.colliders, this.props.initialGroupCenter)
    const viewCenter = this.props.initialViewCenter || cm

    this.state = {
        viewCenter: viewCenter,
        groupCenterDiff: props.fixed ? null : ZERO.retrace(viewCenter, this.props.initialGroupCenter),
        groupCenter: props.fixed ? this.props.initialGroupCenter : null,
        mapType: this.props.initialMapType,
        forceCenter: undefined,
        zoom: this.props.initialZoom,
    }
		this.updateMapCenter = this.updateMapCenter.bind(this)
    this.updateMapZoom = this.updateMapZoom.bind(this)
    this.updateMapType = this.updateMapType.bind(this)
    this.onSearch = this.onSearch.bind(this)
	}

  componentDidMount() {
    ping("/map")
  }

  getGroupCenter() {
    /**
     * Returns the resolved, absolute group center.
     */

    if (this.state.groupCenter !== null) {
      return this.state.groupCenter
    } else {
      return this.state.viewCenter.retrace(ZERO, this.state.groupCenterDiff)
    }
  }

	updateMapCenter(mapProps, map) {
    const newViewCenter = new LatLng(map.center.lat(), map.center.lng())

    this.setState({
      viewCenter: newViewCenter,
    })
    
    const groupCenter = this.getGroupCenter()
    this.props.onCenterChange(groupCenter, newViewCenter)
	}

  componentDidUpdate(prevProps) {
    if (prevProps.propsId != this.props.propsId ) {
      // go. Move to exact location
      const newGroupCenterDiff = ZERO.retrace(this.props.initialViewCenter,
                                              this.props.initialGroupCenter)

      this.setState({
          groupCenterDiff: this.props.fixed ? null : newGroupCenterDiff,
          groupCenter: this.props.fixed ? this.props.initialGroupCenter : null,
          viewCenter: this.props.initialViewCenter,
          forceCenter: this.props.initialViewCenter.dict(),
          zoom: this.props.initialZoom,
          mapType: this.props.initialMapType,
      })
    } else if (!prevProps.fixed && this.props.fixed) {
      // enable fixed
      this.setState({
          groupCenterDiff: null,
          groupCenter: this.state.viewCenter.retrace(ZERO, this.state.groupCenterDiff),
      })
    } else if (prevProps.fixed && !this.props.fixed) {
      // disable fixed
      const cmDiff = geo.get_boxcenter_of_shapes(this.props.colliders, ZERO)
      const newGroupCenterDiff = new LatLng(-cmDiff.lat, -cmDiff.lng)
      this.setState({
          groupCenterDiff: newGroupCenterDiff,
          groupCenter: null,
      })
    } else if (prevProps.colliders != this.props.colliders) {
      // Collider select/deselect
      const groupCenter = this.getGroupCenter()
      const newViewCenter = geo.get_boxcenter_of_shapes(this.props.colliders, groupCenter)
      const newZoom = geo.get_suggested_zoom(this.props.colliders, groupCenter)

      this.setState({
          groupCenterDiff: this.props.fixed ? null :
             this.state.groupCenterDiff.retrace(newViewCenter, this.state.viewCenter),
          groupCenter: this.props.fixed ? groupCenter : null,
          viewCenter: newViewCenter,
          forceCenter: newViewCenter.dict(),
          zoom: newZoom,
      })
      const newGroupCenter = this.getGroupCenter()
      this.props.onCenterChange(newGroupCenter, newViewCenter)
    }
  }

  updateMapZoom(mapProps, map) {
    this.props.onZoomChange(map.zoom)
  }

  updateMapType(mapProps, map) {
    this.setState({mapType: map.mapTypeId})
    this.props.onMapTypeChange(map.mapTypeId)

    // force redraw (Polygon  does not redraw if opacity changes)
    this.setState({
        groupCenterDiff: this.state.groupCenterDiff !== null ? this.state.groupCenterDiff.go(0, 0.01): null,
        groupCenter: this.state.groupCenter !== null ? this.state.groupCenter.go(0, 0.01): null,
    })

  }

  onSearch(input) {
    ping("/search")
  }

  mapTypeOpacity(opacity) {
    /**
     * Returns the mapType aware opacity.
     */
    if (["satellite", "hybrid"].indexOf(this.state.mapType) < 0) {
      return opacity
    } else {
      return 1 - ((1 - opacity) / 1.4)
    }
  }
  
  render() {
    return (
      <GoogleMap google={this.props.google}
					zoom={this.state.zoom}
          searchBox={this.props.searchRef.current}
					onCenter_changed={this.updateMapCenter}
					onZoom_changed={this.updateMapZoom}
          onMaptypeid_changed={this.updateMapType}
          onSearch={this.onSearch}
          mapType={this.state.mapType}
          streetViewControl={false}
          center={this.state.forceCenter}
  				initialCenter={this.state.viewCenter.dict()}> 
				{ this.props.colliders.indexOf(geo.C_PS) > -1 &&
          <Polygon
            paths={geo.get_ps_shape(this.getGroupCenter())}
            fillColor="#00FF00"
            strokeColor="#00FF00"
            strokeOpacity={this.mapTypeOpacity(0.8)}
            fillOpacity={this.mapTypeOpacity(0.1)}
            strokeWeight={2} />
        }
				{ this.props.colliders.indexOf(geo.C_SPS) > -1 &&
          <Polygon
            paths={geo.get_sps_shape(this.getGroupCenter())}
            fillColor="#00CC99"
            strokeColor="#00CC99"
            strokeOpacity={this.mapTypeOpacity(0.8)}
            fillOpacity={this.mapTypeOpacity(0.1)}
            strokeWeight={2} />
        }
				{ this.props.colliders.indexOf(geo.C_LHC) > -1 &&
          <Polygon
            paths={geo.get_lhc_shape(this.getGroupCenter())}
            strokeColor="#0099CC"
            fillColor="#0099CC"
            strokeOpacity={this.mapTypeOpacity(0.8)}
            fillOpacity={this.mapTypeOpacity(0.1)}
            strokeWeight={2} />
        }
				{ this.props.colliders.indexOf(geo.C_TEV) > -1 &&
          <Polygon
            paths={geo.get_tevatron_shape(this.getGroupCenter())}
            strokeColor="#AA00FF"
            fillColor="#AA00FF"
            strokeOpacity={this.mapTypeOpacity(0.8)}
            fillOpacity={this.mapTypeOpacity(0.1)}
            strokeWeight={2} />
        }
				{ this.props.colliders.indexOf(geo.C_FCC) > -1 &&
          <Polygon
            paths={geo.get_fcc_shape(this.getGroupCenter())}
            fillColor="#0000FF"
            strokeColor="#0000FF"
            strokeOpacity={this.mapTypeOpacity(0.6)}
            fillOpacity={this.mapTypeOpacity(0.1)}
            strokeWeight={3} />
        }
				{ this.props.colliders.indexOf(geo.C_CLIC) > -1 &&
          <Polyline
            path={geo.get_clic_s3_shape(this.getGroupCenter())}
            strokeColor="#66BB00"
            strokeOpacity={this.mapTypeOpacity(0.6)}
            strokeWeight={2} />
        }
				{ this.props.colliders.indexOf(geo.C_CLIC) > -1 &&
          <Polyline
            path={geo.get_clic_s2_shape(this.getGroupCenter())}
            strokeColor="#338800"
            strokeOpacity={this.mapTypeOpacity(0.6)}
            strokeWeight={4} />
        }
				{ this.props.colliders.indexOf(geo.C_CLIC) > -1 &&
          <Polyline
            path={geo.get_clic_s1_shape(this.getGroupCenter())}
            strokeColor="#005500"
            strokeOpacity={this.mapTypeOpacity(0.6)}
            strokeWeight={6} />
        }
				{ this.props.colliders.indexOf(geo.C_ILC) > -1 &&
          <Polyline
            path={geo.get_ilc_p2_shape(this.getGroupCenter())}
            strokeColor="#FF0000"
            strokeOpacity={this.mapTypeOpacity(0.6)}
            strokeWeight={3} />
        }
				{ this.props.colliders.indexOf(geo.C_ILC) > -1 &&
          <Polyline
            path={geo.get_ilc_p1_shape(this.getGroupCenter())}
            strokeColor="#CC3300"
            strokeOpacity={this.mapTypeOpacity(0.6)}
            strokeWeight={5} />
        }
      </GoogleMap>
    );
  }
}

MapContainer.propTypes = {
  colliders: PropTypes.array.isRequired,
  initialGroupCenter: PropTypes.object.isRequired,
  initialGroupCenter: PropTypes.object.isRequired,
  initialZoom: PropTypes.number.isRequired,
  initialMapType: PropTypes.string.isRequired,
  propsId: PropTypes.number.isRequired,
  fixed: PropTypes.bool.isRequired,
  onCenterChange: PropTypes.func.isRequired,
  onZoomChange: PropTypes.func.isRequired,
  onMapTypeChange: PropTypes.func.isRequired,
}

export default GoogleApiWrapper({
    apiKey: API_KEY
})(MapContainer)
