import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const MenuCollider = ({name, loc, onChange, onLocate, checked, abbr="", info="",
                       future=false, color}) => {
  let props = {}
  if (color) {
    props.style = {color: color}
  }
  return (
      <li>
        <label className={classNames({future: future})}>
          <input type="checkbox" checked={checked}
            onChange={e => onChange(e.currentTarget.checked)}/>
          {name} {abbr != "" && <span>({abbr})</span>}
        </label>
        { info &&
          <a href={info} className="info"
          {...props}
          target="_blank">ⓘ</a>
        }


        <a href="#" className="loc"
          onClick={() => onLocate()}>@{loc}</a>
      </li>
    )
}

MenuCollider.propTypes = {
  name: PropTypes.string.isRequired,
  loc: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  onLocate: PropTypes.func.isRequired,
  abbr: PropTypes.string,
  info: PropTypes.string,
  future: PropTypes.bool,
  color: PropTypes.string,
}

export default MenuCollider
