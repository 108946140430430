
import React from 'react'
import {Link} from 'react-router-dom'

export default class Consent extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
      consent: window.localStorage.getItem("google_consent") == "yes"
    }

  }

  consent() {
    window.localStorage.setItem("google_consent", "yes")
    this.setState({consent: true})

  }

  render() {
    if (this.state.consent) {
      return (<div>{this.props.children}</div>)
    } else {
      return (
        <div className="nog"><div>
          <p>
            This website uses Google Maps to show the size of particle
            accelerators. We need your consent to load the map by using the Google Maps
            API. More information can be found in our <Link
            to="/legal">Privacy Policy</Link>.
          </p>
          <button onClick={() => this.consent() }>I agree to the Privacy Policy
          and want to load Google Maps.</button>
        </div></div>
      )
    }

  }
}
